$breakpoint1: 1100px;
$breakpoint2: 850px;
$breakpoint3: 700px;
$breakpoint4: 500px;

#about-page {
  background-color: #ffffff;
  width: 100%;
  // overflow: auto;

  .about-page_masthead {
    position: fixed;
    z-index: 9999;
    display: flex;
    padding: 15px 24px;
    width: 100%;
    background-color: #3273fa;

    .ugg-logo {
      width: 80px;
      flex: 0 0 80px;
    }
  }

  .about_content-container {
    max-width: 1016px;
    margin: 0 auto;
    box-sizing: content-box;

    @media screen and (max-width: $breakpoint1) {
      max-width: 800px;
    }
    @media screen and (max-width: $breakpoint2) {
      max-width: 650px;
    }
  }

  .section-header {
    margin: 0;
    padding-top: 100px;
    padding-bottom: 40px;
    color: #191937;
    font-family: "Proxima Nova";
    font-size: 40px;
    font-weight: 700;

    @media screen and (max-width: $breakpoint1) {
      padding-top: 80px;
      padding-bottom: 30px;
      font-size: 36px;
    }
    @media screen and (max-width: $breakpoint2) {
      font-size: 30px;
    }
    @media screen and (max-width: $breakpoint3) {
      padding-top: 48px;
      padding-bottom: 24px;
    }
    @media screen and (max-width: $breakpoint4) {
      padding-top: 36px;
      padding-bottom: 20px;
      font-size: 24px;
    }
  }

  .section-blurb {
    color: #191937;
    font-family: "Barlow";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;

    @media screen and (max-width: $breakpoint1) {
      font-size: 18px;
    }
    @media screen and (max-width: $breakpoint2) {
      font-size: 16px;
    }
  }

  .about_header {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #3273fa;

    @media screen and (max-width: $breakpoint1) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media screen and (max-width: $breakpoint3) {
      padding-bottom: 48px;
    }

    h1 {
      margin: 0;
      margin-bottom: 100px;
      font-family: "Proxima Nova";
      color: #ffffff;
      font-size: 100px;
      font-weight: 700;

      @media screen and (max-width: $breakpoint1) {
        margin-bottom: 75px;
        font-size: 80px;
      }
      @media screen and (max-width: $breakpoint2) {
        margin-bottom: 40px;
        font-size: 60px;
      }
      @media screen and (max-width: $breakpoint3) {
        font-size: 48px;
      }
    }

    .header-section {
      position: relative;

      .header-blurb {
        width: 393px;
        color: #ffffff;

        @media screen and (max-width: $breakpoint2) {
          width: auto;
        }
      }

      .lab-img {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 665px;
        width: calc(100% - 393px - 60px);
        border-radius: 6px;
        overflow: hidden;

        @media screen and (max-width: $breakpoint1) {
          width: calc(100% - 393px - 24px);
        }
        @media screen and (max-width: $breakpoint2) {
          position: static;
          margin-top: 24px;
          width: 100%;
        }

        img {
          width: 100%;

          @media screen and (max-width: $breakpoint2) {
            margin: -20% 0;
          }
        }
      }
    }
  }

  .about_tx {
    padding-bottom: 32%;
    background-image: url("http://static.bigbrain.gg/assets/ugg/pages/about/austin-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;

    .spacer {
      height: 100px;

      @media screen and (max-width: $breakpoint1) {
        display: none;
      }
    }

    .section-blurb {
      width: 665px;

      @media screen and (max-width: $breakpoint2) {
        width: auto;
      }
    }
  }

  .about_our-team {
    .section-blurb {
      margin-bottom: 50px;
      width: 665px;

      @media screen and (max-width: $breakpoint1) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: $breakpoint2) {
        width: auto;
      }
    }

    .team-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 24px 30px;

      @media screen and (max-width: $breakpoint2) {
        grid-gap: 24px;
      }
      @media screen and (max-width: $breakpoint3) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
      }

      .team-member {
        display: grid;
        grid-template-rows: min-content min-content 1fr;

        .headshot {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          max-width: 314px;
          border-radius: 6px;

          > img {
            max-width: 100%;
            height: auto;
          }
        }

        .member-title {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 30px 0 24px;
          padding-left: 30px;
          height: 50px;
          color: #191937;
          font-family: "Barlow";
          font-size: 24px;

          @media screen and (max-width: $breakpoint1) {
            height: 40px;
            font-size: 20px;
          }

          @media screen and (max-width: $breakpoint2) {
            margin: 24px 0 20px;
            padding-left: 20px;
            font-size: 16px;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 6px;
            height: 100%;
            border-radius: 3px;
            background-color: #3273fa;
          }

          .name {
            font-weight: 500;
          }
          .position {
            font-weight: 700;
          }
        }

        .member-desc {
          color: #5f5f7b;
          font-family: "Barlow";
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;

          @media screen and (max-width: $breakpoint1) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .about_press {
    margin-top: 100px;
    padding-bottom: 100px;
    background-color: #3273fa;

    @media screen and (max-width: $breakpoint1) {
      margin-top: 80px;
      padding-bottom: 80px;
    }
    @media screen and (max-width: $breakpoint2) {
      margin-top: 48px;
      padding-bottom: 48px;
    }

    .section-header {
      color: #ffffff;

      @media screen and (max-width: $breakpoint2) {
        padding-top: 48px;
      }
    }

    .press-links {
      display: flex;
      flex-direction: column;

      a {
        font-family: "Courier";
        color: #ffffff;
        font-size: 20px;
        line-height: 42px;
        text-decoration: underline;

        @media screen and (max-width: $breakpoint1) {
          font-size: 24px;
          line-height: 30px;
        }
        @media screen and (max-width: $breakpoint2) {
          font-size: 18px;
          line-height: 24px;
        }

        &:hover {
          color: #191937;
        }

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:41";