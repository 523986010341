.rank-block {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include responsive("TABLET", false) {
    grid-area: 1 / 1 / 2 / 3;
  }
  @include responsive("MOBILE_LARGE", false) {
    grid-area: 1 / 1 / 2 / 3;
  }

  .rank-block_filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    @include responsive("MOBILE_LARGE") {
      margin-bottom: 12px;
    }
  }

  .rank-block_loading {
    flex: 1;
    display: flex;
    align-items: center;
    min-height: 168px;
  }

  .rank-list {
    display: grid;
    grid-gap: 12px;

    @include responsive("TABLET", false) {
      grid-template-rows: 1fr;
    }
    @include responsive("MOBILE_LARGE", false) {
      grid-template-rows: 1fr;
    }

    .rank-content {
      display: flex;
      flex-direction: column;
      padding: 18px;
      border-radius: 3px;
      background-color: #191937;

      &.is_open {
        border-radius: 3px 3px 0px 0px;
      }

      @include responsive("MOBILE_LARGE") {
        padding: 12px;
      }

      // @include responsive("DESKTOP_SMALL", false) {
      //   height: 100%;
      // }
      // @include responsive("TABLET", false) {
      //   height: 100%;
      // }
      // @include responsive("MOBILE_LARGE", false) {
      //   height: 100%;
      // }

      .rank-image-container {
        @include flex-center;
        flex: none;
        width: 54px;
        height: 54px;
        margin-right: 12px;
        margin-left: 2px;

        .rank-img {
          flex: 0 0 54px;
          width: 54px;
        }
      }

      .queue-container {
        .title {
          display: flex;
        }

        .rank-content-header {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .unranked {
          font-weight: 400;
          font-size: 14px;
          color: #cddcfe;
        }

        .queue-type {
          font-weight: 700;
          color: #ffffff;
          font-size: 14px;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .rank-sub-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #cddcfe;
        margin-top: 16px;
      }

      .text-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .rank-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 2px;
        color: white;
      }

      .promos {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fill, minmax(min-content, 10px));
        gap: 4px;
        margin-top: 5px;

        .promo_game {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #383864;

          &.promo_game__win {
            background-color: #3273fa;
          }
          &.promo_game__loss {
            background-color: #ff4e50;
          }
        }
      }

      .rank-text {
        display: flex;
        justify-items: flex-start;
        flex-direction: column;
        font-size: 12px;
        font-weight: 500;
      }

      .rank-wins {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-items: center;
        font-size: 12px;

        .total-games {
          margin-left: 6px;
        }
      }
    }

    .rank-lp-container {
      @include responsive("DESKTOP_SMALL") {
        display: grid;
        margin-bottom: auto;
      }
    }

    .unranked-container {
      height: 100%;
    }
  }

  .graph-container {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(0, min-content));
    grid-gap: 12px;
  }

  .rank-block-lp-graph {
    border-radius: 0px 0px 3px 3px;
    padding: 18px;
    border-top: 1px solid #070720;
  }
}

;@import "sass-embedded-legacy-load-done:54";