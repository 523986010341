.match-summary_mobile {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  padding: 6px 9px;

  .content-container {
    width: 100%;

    .match-outcome {
      display: flex;
      justify-content: space-between;

      .win-lp-stat {
        height: 100%;
        margin-top: -2px;
      }

      .queue-type-time {
        margin-bottom: 9px;
        height: 100%;
      }

      .victory-status {
        font-size: 11px;
        font-weight: 700;

        .match_gold & {
          color: #ff9b00;
        }
        .match_win & {
          color: #3273fa;
        }
        .match_lose & {
          color: #ff4e50;
        }
        .match_remake & {
          color: #cddcfe;
        }
      }

      .match-lp {
        display: flex;
        align-items: center;
        margin-left: 6px;

        .lp-arrow {
          width: 5px;
          flex: 0 0 5px;
        }

        .lp-diff-container {
          @include flex-center;

          &.ranked-up {
            .lp-gain_loss,
            .promos {
              display: none;
            }
            .rank-up {
              .lp-arrow {
                margin-right: 2px;
              }
            }
          }

          &.promotion-failed {
            .lp-gain_loss {
              display: none;
            }
          }
        }

        .rank-up {
          .promoted-tier-img {
            width: 16px;
            flex: 0 0 16px;
          }
          .promoted-rank {
            color: #ffffff;
            font-size: 11px;
            font-weight: 700;
          }
        }

        .placement-progress {
          .current-placement-match {
            font-size: 11px;
          }
          .total-placement-matches {
            font-size: 11px;
          }
        }

        .lp-value {
          margin-left: 2px;
          font-size: 11px;
          font-weight: 700;
        }

        .no-diff {
          color: #5f5f7b;
          font-size: 11px;
          font-weight: 700;
        }

        .promos {
          .promo-target {
            margin-right: 3px;
            > img {
              width: 16px;
            }
          }
        }
      }
      .queue-type {
        color: #ffffff;
        font-size: 11px;
        font-weight: 700;
      }
      .from-now {
        margin-left: 6px;
        color: #cddcfe;
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
      }
    }

    .match-details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .champion-details {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -8px;

        .champion-face {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52px;
          height: 52px;
          border-radius: 3px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transform: scale(1.1);
          }
        }
        .champion-level {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 16px;
          border-radius: 3px;
          background-color: #191937;
          font-size: 10px;
          font-weight: 500;
          text-align: center;
        }

        .summoner-spells {
          display: flex;
          flex-direction: column;
          margin-right: 4px;

          .summoner-spell {
            width: 22px;
            height: 22px;
            border-radius: 3px;
            background-color: #191937;

            &:first-child {
              margin-bottom: 4px;
            }

            > img {
              width: 22px;
              height: 22px;
              border-radius: 3px;
            }
          }
        }

        .runes {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;

          .rune {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            background-color: #191937;
            border-radius: 3px;

            .match_gold & {
              background-color: #563a17;
            }
            .match_win & {
              background-color: #223b80;
            }
            .match_lose & {
              background-color: #53263e;
            }

            .match_remake & {
              background-color: #252554;
            }

            &:first-child {
              margin-bottom: 4px;
            }

            img {
              width: 18px;
              height: 18px;
            }

            &_sub-style {
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
      .match-stats {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 43px;

        .multikill {
          margin-right: 12px;
          width: 18px;
        }

        .items {
          .items-container {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: repeat(auto-fit, 18px);
            grid-gap: 4px;

            .item-wrapper {
              border-radius: 3px;
              background-color: #383864;
              //background-color: #22397C;
              overflow: hidden;
              .match_gold & {
                background-color: rgba(86, 58, 23, 1);
              }
              .match_win & {
                background-color: rgba(34, 59, 128, 1);
              }
              .match_lose & {
                background-color: rgba(83, 38, 62, 1);
              }

              .match_remake & {
                background-color: rgba(37, 37, 84, 1);
              }
            }
          }
        }

        .KDA-totals {
          font-size: 11px;
          font-weight: 700;
          white-space: nowrap;
          .red {
            color: #ff4e50;
          }

          .slash {
            color: #5f5f7b;
          }
        }

        .KDA-ratio,
        .cs {
          font-size: 11px;
          font-weight: 700;

          .faded {
            color: #cddcfe;
            font-weight: 400;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:60";