.leaderboards-page {
  display: flex;
  margin: 24px auto 0;

  @include responsive("TABLET") {
    width: 100%;
  }

  .side-ads_sticky-group {
    margin: 0;
  }

  .leaderboards_content-container {
    width: 1016px;

    @include responsive("TABLET") {
      width: 100%;
    }
  }

  .leaderboard_header {
    h1 {
      margin: 0;
      font-family: "Barlow";
      font-size: 36px;
      line-height: 34px;
      font-weight: 600;
      color: #ffffff;
    }

    h3 {
      margin: 0;
      margin-top: 12px;
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      color: #cddcfe;
    }
  }

  .leaderboard_nav {
    margin-top: 24px;
  }

  .leaderboard_filters {
    display: flex;
    align-items: center;
    margin: 24px 0;

    .filter-label {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-size: 14px;
      font-weight: 700;

      @include responsive("MOBILE_LARGE") {
        margin-right: 12px;
      }

      svg {
        width: 18px;
      }
      span {
        margin-left: 10px;
      }
    }
  }

  .leaderboard_summoner-input {
    position: relative;
    display: flex;
    align-items: center;

    input {
      padding: 0 34px 0 12px;
      width: 300px;
      height: 36px;
      border-radius: 3px;
      border: 1px solid #191937;
      background-color: #191937;
      outline: none;
      color: #cddcfe;
      font-size: 12px;

      &:focus {
        border: 1px solid #3273fa;
      }
    }

    svg {
      position: absolute;
      right: 10px;
      width: 20px;

      &:hover {
        cursor: pointer;
      }

      path {
        fill: #ffffff;
      }
    }
  }

  .leaderboard-ranking_filters-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;

    .leaderboard_filters {
      margin: 0;
    }

    @include responsive("MOBILE_MEDIUM") {
      .leaderboard_summoner-input {
        display: none;
      }
    }
  }

  .leadboard_table_loading {
    display: flex;
    justify-content: center;
    min-height: 100vh;

    .spinthatshit-loader {
      align-items: flex-start;
      margin-top: 64px;
    }
  }

  .leaderboard_table_error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 12px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    img {
      margin-bottom: 15px;
      min-width: 50px;
      max-width: 200px;
    }

    a {
      text-decoration: underline;
    }
  }

  @import "./leaderboard-top-five";
  @import "./leaderboard-table";
  @import "./leaderboard-pagination";
}

;@import "sass-embedded-legacy-load-done:89";