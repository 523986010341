.region-selector-container {
  .region-selector-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 200px;

    .region-selector-item {
      margin-bottom: 18px;
      display: flex;
      align-items: center;

      &.region-selector-item_disabled {
        opacity: 0.5;
        filter: grayscale(70%);
      }
    }
    .region-selector-item:not(:nth-child(3n)) {
      margin-right: 24px;
    }
    .region-selector-item:nth-child(3n + 1):nth-last-child(-n + 3),
    .region-selector-item:nth-child(3n + 1):nth-last-child(-n + 3) ~ div {
      margin-bottom: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #cddcfe;
    }
  }
}

.region-entry {
  display: flex;
  align-items: center;
  padding: 8px;

  &.region_selected {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    background-color: var(--filter-option-selected);
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

.region-text {
  font-family: "Roboto";
  font-size: 12px;
  color: var(--font-color-main);
}

.region-text-selected {
  font-family: "Roboto";
  font-size: 12px;
  color: var(--font-color-main);
}

;@import "sass-embedded-legacy-load-done:47";