.error-404 {
  margin: 0 auto;
  min-width: 1200px;
  max-width: 1200px;

  .did-you-mean {
    display: flex;
    align-items: center;
    padding: 44px 0;
    border-bottom: 1px solid $grey-border;

    .champion-face {
      position: relative;
      margin-right: 10px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid $grey-border;
      overflow: hidden;

      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    span {
      font-size: 24px;
    }

    a,
    a:hover,
    a:visited,
    a:focus {
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-right: 8px;
      font-size: 24px;
      font-weight: bold;
      font-style: italic;
      color: #ffffff;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .page-not-found {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 95px 0;
    border-bottom: 1px solid $grey-border;

    hr {
      margin: 50px 0;
      border-color: $grey-border;
    }

    h1 {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
    }

    span {
      font-size: 24px;
    }

    .back-btn {
      display: flex;
      align-items: center;
      margin-top: 55px;
    }

    .img-404 {
      width: 650px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:44";