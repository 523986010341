.faq-container {
  h1 {
    margin-top: 0;
  }

  .banner-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 148px;
    border-bottom: 1px solid $grey-border;
    background-color: $ugg-landing-blue;
    font-size: 36px;
    font-weight: bold;
  }

  .faqs {
    margin: 0 auto;
    max-width: 1200px;
    padding: 80px 0;
  }

  .faq-block {
    padding: 24px;
    border-bottom: 1px solid $grey-border;

    &:hover {
      background-color: $ugg-analytics-blue;
    }
    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .plus-icon {
        width: 20px;
        height: 20px;
        transform: rotateZ(0deg);
        transform-origin: center;
        transition: transform 0.5s;
        backface-visibility: hidden;

        svg g#UGG_FAQ_v1a {
          fill: #ffffff;
          transition: fill 0.5s;
        }
      }
    }

    .answer {
      padding-top: 24px;
      width: 90%;
      font-size: 12px;
      line-height: 1.414;
      color: #ebebf0;

      img {
        margin-top: 14px;
      }
    }

    &.open {
      .plus-icon {
        transform: rotateZ(-45deg);

        svg g#UGG_FAQ_v1a {
          fill: red;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:73";