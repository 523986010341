.multikill-indicator-container {
  height: 18px;
  display: flex;
  align-items: center;
  border-radius: 9px;
  font-size: 10px;
  font-weight: 700;
  background-color: #06294a;

  &.penta {
    background-color: rgba(#ff9b00, 0.25);
  }
  &.quadra {
    background-color: rgba(#3273fa, 0.25);
  }
  &.triple {
    background-color: rgba(#7ad0d7, 0.25);
  }
  &.double {
    background-color: rgba(#383864, 0.5);
  }

  .multikill-icon {
    @include flex-center;
    width: 18px;
    height: 18px;
    border-radius: 50%;

    &.penta {
      background-color: #ff9b00;
    }
    &.quadra {
      background-color: #3273fa;
    }
    &.triple {
      background-color: #7ad0d7;
    }
    &.double {
      background-color: #626290;
    }

    svg {
      width: 10px;
      flex: 0 0 10px;

      path {
        fill: #ffffff;
      }
    }
  }

  .multikill-text {
    margin-right: 5px;
    margin-left: 4px;
  }
}

;@import "sass-embedded-legacy-load-done:63";