.Notification {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  width: 28px;
  height: 28px;
  border-radius: 3px;

  .notification-dropdown-container ~ .notification-icon {
    border-radius: 3px;
    background-color: #414165;
  }

  .notification-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 3px;

    &:hover {
      background-color: #25254b;
      cursor: pointer;
    }
  }

  .divider {
    width: 1px;
    height: 100%;
    margin: 0px 24px 0px;
  }

  .new-announcement-count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 8px;
    transform: translateX(50%);
    padding: 0 3px;
    border-radius: 100%;
    box-shadow: 0 0 6px #ff4e50;
    background-color: #ff4e50;
    font-size: 10px;
    font-weight: 700;
  }

  .new-announcement {
    height: 10px;
    width: 10px;
    border: 2px solid #070720;
    background-color: #ff4655;
    border-radius: 50%;
    position: absolute;
    left: 15px;
    bottom: 15px;
  }

  .background-circle {
    height: 6px;
    width: 6px;
    box-shadow: 0px 0px 2px 0px #ff4655;
    background-color: #ff4655;
    border-radius: 50%;
    position: absolute;
    left: 17px;
    bottom: 17px;
  }

  .notification-dropdown-container {
    position: absolute;
    top: 38px;
    right: 0px;

    background-color: #191937;
    box-shadow: 0px 3px 5px rgba(7, 7, 32, 0.6);
    border-radius: 3px;
    width: 336px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    font-family: "Inter";
    font-style: normal;

    .gg-bet-promo-notif {
      margin: auto;
      text-align: center;
      border-bottom: 1px solid #414165;
      margin-bottom: 18px;
      padding-bottom: 18px;
      position: relative;

      .ggbet-logo-notif-svg {
        height: 40px;
        width: 205px;
        justify-content: center;
        margin: auto;
      }
      .gg-bet-promo-message-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #cddcfe;
        height: 15px;
        text-align: center;
        margin-top: 18px;
      }

      .gg-bet-promo-message-code {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #ffffff;
        background: #11112a;
        border: 1px dashed #414165;
        border-radius: 3px;
        width: 186px;
        height: 50px;
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
      }

      .gg-bet-promo-message-button {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
        background: #3273fa;
        border-radius: 3px;
        height: 36px;
        width: 300px;
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
      }
      .gg-bet-promo-message-button:hover {
        background: #2963da;
      }

      .ad-indication {
        position: absolute;
        top: 0px;
        right: 18px;
        width: 20px;
        height: 10px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #cddcfe;
        background: rgba(20, 23, 28, 0.6);
      }
    }

    .header-title {
      padding-top: 18px;
      padding-bottom: 18px;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      margin-left: 18px;
      margin-right: 18px;
      border-bottom: 1px solid #414165;
      margin-bottom: 18px;
    }

    .mark-all-as-read {
      position: absolute;
      top: 22px;
      right: 18px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-decoration: underline;
      color: #3273fa;
      cursor: pointer;
    }

    .no-new-notifications {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 18px;
      color: rgba(205, 220, 254, 0.4);
    }
  }

  .notification-entry-container {
    display: flex;
    margin-right: 18px;
    margin-left: 18px;
    font-family: "Inter";
    font-style: normal;
    font-size: 12px;
    font-weight: 500;

    // This css box is used in order to offset each text and date box for the icon and normally does not have any content.
    .icon-align {
      display: flex;
      position: relative;
      margin-bottom: 12px;
    }

    .new-notification-icon {
      position: absolute;
      width: 6px;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 3px;
    }
    .priority-notification-icon {
      position: absolute;
      width: 24px;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 3px;
    }

    // Text container for when messages are marked as read.
    .text-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      // Reduce color of text to indicate that the message has been marked as read.
      color: rgba(#cddcfe, 0.4);
    }

    .text-container-new {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .priority-title-body-text {
      color: rgba(#ff4e50, 1);
    }

    .body-text {
      line-height: 15px;
      padding-left: 24px;
    }

    .date-text {
      height: 12px;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 18px;
      padding-left: 24px;
    }

    .notification-seperator {
      display: flex;
      margin-bottom: 18px;
      border-bottom: 1px solid #414165;
      margin-left: 24px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:86";