.additional-stats {
  // display: flex;
  // justify-content: flex-start;
  // flex-direction: column;
  // flex-wrap: wrap;
  // background-color: #191937;
  // border-radius: 6px;
  // flex: 1 0 300px;
  // padding: 18px 24px;

  // .header-container {
  //   display: flex;
  //   align-items: center;

  //   .blue-bar {
  //     height: 20px;
  //     width: 2px;
  //     background: #3273fa;
  //     border-radius: 2px;
  //   }

  //   .header {
  //     font-weight: 700;
  //     font-size: 14px;
  //     line-height: 20px;
  //     margin-left: 12px;
  //   }
  // }

  .main-content {
    // font-family: "Inter";
    // font-style: normal;
    // font-weight: 700;
    // font-size: 14px;
    // height: 100px;
    // overflow-y: auto;
    // color: #bbbedb;
    // margin-top: 5px;

    .list {
      // padding: 0px;
      // font-weight: 200;
      // margin: 0px;
      // list-style-type: disc;

      .ability {
        margin-top: 4px;
        line-height: 8px;

        .image {
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }

        .title {
          padding: 6px 0px;
          font-size: 14px;
          display: flex;
          align-content: center;
        }
        .description {
          padding-left: 12px;
          margin-bottom: 12px;
        }
        .bullets {
          line-height: 20px;
          margin: 4px 14px 0px 0px;
        }
      }

      .bold-right {
        font-weight: 700;
        margin-right: 4px;
        color: white;
      }

      .bold-left {
        font-weight: 700;
        margin-left: 4px;
        color: white;
      }

      .entireChange {
        display: flex;
        align-items: center;
        padding: 0px;
      }

      .bullet {
        height: 5px;
        width: 5px;
        margin-right: 7px;
        border-radius: 50%;
        background-color: #bbbedb;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:78";