@import "~@ugg/shared/stylesheets/summoner-profile/match-card/desktop-match-summary";
@import "~@ugg/shared/stylesheets/summoner-profile/match-card/mobile-match-summary";

.match-summary {
  .new-game-indicator {
    position: absolute;
    left: 0;
    top: 50%;
    width: 10px;
    height: 10px;
    border: 3px solid #11112a;
    border-radius: 50%;
    background-color: #ff9b00;
    transform: translate(-50%, -50%);
    box-sizing: content-box;
  }

  .new-game-indicator {
    @keyframes indicator-enter {
      0% {
        opacity: 0;
        transform: scale(0) translate(-50%, -50%);
      }
      100% {
        opacity: 0;
        transform: scale(1) translate(-50%, -50%);
      }
    }

    position: absolute;
    left: 0;
    top: 50%;
    width: 10px;
    height: 10px;
    border: 3px solid #11112a;
    border-radius: 50%;
    background-color: #ff9b00;
    // transform: scale(1) translate(-50%, -50%);
    box-sizing: content-box;
    animation: 200ms indicator-enter;
  }

  .expand-match-icon {
    @include flex-center;
    cursor: pointer;
    margin-top: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;

    &.is-expanded {
      transform: rotateX(180deg);
    }

    svg {
      width: 18px;
      flex: 0 0 18px;

      .ring {
        .match_gold & {
          fill: #ff9b00;
        }
        .match_win & {
          fill: #3273fa;
        }
        .match_lose & {
          fill: #ff4e50;
        }
        .match_remake & {
          fill: #414165;
        }
      }
    }
  }

  .placement-matches {
    display: flex;
    align-items: center;

    .placement-label {
      @include flex-center;
      margin-right: 2px;
      width: 12px;
      height: 12px;
      border-radius: 3px;
      font-size: 8px;
      font-weight: 500;

      .match_gold & {
        color: #ff9b00;
        background-color: rgba(#ff9b00, 0.25);
      }
      .match_win & {
        color: #3273fa;
        background-color: rgba(#3273fa, 0.25);
      }
      .match_lose & {
        color: #ff4e50;
        background-color: rgba(#ff4e50, 0.25);
      }
      .match_remake & {
        color: #414165;
        background-color: rgba(#414165, 1);
      }
    }

    .placement-progress {
      display: flex;
      .current-placement-match {
        color: #ffffff;
        font-weight: 700;
      }
      .total-placement-matches {
        color: #cddcfe;
        font-weight: 500;
      }
    }
  }

  .match-lp {
    .lp-arrow {
      &.gain {
        path {
          fill: #3273fa;
        }
      }
      &.loss {
        path {
          fill: #ff4e50;
        }
      }
      path {
        &.match_gold {
          fill: #ff9b00;
        }
      }
    }

    .lp-gain_loss {
      display: flex;
      align-items: center;
    }

    .rank-up {
      display: flex;
      align-items: center;

      .lp-arrow {
        path {
          fill: #ffffff;
        }
      }
    }

    .promos {
      display: flex;
      align-items: center;

      .promo-progress {
        display: flex;
        align-items: center;

        .promo_game {
          position: relative;
          display: flex;
          align-items: center;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          // border: 1px solid #191937;
          background-color: #383864;

          &:not(:last-child) {
            margin-right: 3px;
            // &::after {
            //   content: "";
            //   position: absolute;
            //   left: calc(100% + 1px);
            //   width: 4px;
            //   height: 1px;
            //   background-color: #191937;
            // }
          }

          .match_gold & {
            background-color: rgba(#ff9b00, 0.25);
          }
          .match_win & {
            background-color: rgba(#3273fa, 0.25);
          }
          .match_lose & {
            background-color: rgba(#ff4e50, 0.25);
          }
          .match_remake & {
            background-color: rgba(#414165, 1);
          }

          &.promo_game__win {
            background-color: #3273fa;
          }
          &.promo_game__loss {
            background-color: #ff4e50;
          }
        }
      }
    }
  }
}

.match-summary_lp-tooltip {
  padding: 12px;
  width: 380px;

  .divider {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: #25254b;
  }

  p,
  li {
    margin: 0;
    font-size: 14px;
    color: #cddcfe;
    line-height: 21px;
  }

  li {
    position: relative;
    display: flex;
    margin-top: 24px;
    padding-left: 12px;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 10px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #cddcfe;
    }
  }

  a {
    font-weight: 700;
    color: #ffffff;
    text-decoration: underline;
  }

  .lp-priority {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
      flex: 0 0 29px;
      width: 29px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:58";