.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  height: 16px;
  opacity: 1;
  border-radius: 1px;
  color: #ffffff;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  transition: opacity $side-nav-transition-duration;

  .side-nav_collapsed & {
    opacity: 0;
  }

  &_mini {
    position: absolute;
    top: 2px;
    left: 28px;
    padding: 0 2px;
    width: 10px;
    height: 10px;
    opacity: 0;
    border-radius: 1px;
    font-size: 8px;
    line-height: 10px;
    pointer-events: none;

    .side-nav_collapsed & {
      opacity: 1;
    }
  }

  &.new {
    background-color: #3273fa;
  }
  &.beta {
    background-color: #24a87b;
  }
  &.soon {
    background-color: #25254b;

    sup {
      font-size: 6px;
    }
  }
}

.side-nav_links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  // margin: 0 12px;
  padding: 0 12px;
  padding-bottom: 12px;
  overflow-y: overlay;
  overflow-x: hidden;
  font-family: "Inter";

  .side-nav_collapsed & {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .side-nav_expanded & {
    @include clean-scrollbar(12px, #0d0d28, 0px, #cddcfe, 6px, 2px);
  }
}

.side-nav_link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
  border-radius: 3px;
  overflow: hidden;
  &.download-button {
    background-color: #ff4655;
    .side-nav_link__label {
      color: #ffffff;
    }
    div {
      svg {
        g,
        path {
          fill: #ffffff;
        }
      }
    }
    &:hover {
      background-color: #ff3a3c !important;
    }
  }

  &:hover:not(.side-nav_link__active) {
    background-color: #070720;

    .side-nav_link__dropdown-button {
      background-color: #0d0d28;
    }

    @include side-nav-light-theme {
      background-color: #dfe7fc;
    }
  }

  &__active {
    background-color: #3273fa;

    &:hover {
      background-color: #1154df;

      .side-nav_link__dropdown-button {
        background-color: #0540ba;
      }
    }
  }
}

.side-nav_link__icon {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  > img,
  > svg {
    width: 20px;
    height: 20px;

    g,
    path {
      fill: #5c5c8e;

      @include side-nav-light-theme {
        fill: #5f648a;
      }
    }
  }

  .side-nav_link__active & {
    > svg {
      g,
      path {
        fill: #ffffff;
      }
    }
  }
}

.side-nav_link__label {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #5c5c8e;
  white-space: nowrap;
  overflow: hidden;
  @include side-nav-fade;

  .side-nav_link__active & {
    color: #ffffff;
  }

  .tag {
    margin-left: 8px;
  }
}

.side-nav_link__dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #070720;
  @include side-nav-fade;

  @include side-nav-light-theme {
    background-color: #cddcfe;
  }

  svg {
    flex: 0 0 8px;
    transition: rotate 0.2s;

    g,
    path {
      fill: #5c5c8e;

      @include side-nav-light-theme {
        fill: #383864;
      }
    }
  }

  .side-nav_link__active & {
    background-color: #184fc1;
    svg {
      g,
      path {
        fill: #ffffff;
      }
    }
  }
}

.side-nav_dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;

  .side-nav_link {
    height: 36px;
    background-color: unset;

    &__label {
      font-weight: 600;
      font-size: 14px;
      color: #cddcfe;

      @include side-nav-light-theme {
        color: #5f648a;
      }
    }
  }

  .side-nav_link:hover {
    background-color: #070720;
  }

  .side-nav_link:hover,
  .side-nav_link__active {
    .side-nav_link__label {
      color: #ffffff;

      @include side-nav-light-theme {
        color: #383864;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:96";