@keyframes footer-overflow {
  0% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.side-nav_footer {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto;
  height: 62px;
  background-color: #191937;

  @include side-nav-light-theme {
    background: #dfe7fc !important;
  }

  .side-nav_collapsed & {
    overflow: hidden;
  }

  .side-nav_expanded & {
    // Hacky way to make sure the "..." box can show popup
    animation: footer-overflow $side-nav-transition-duration forwards;
  }

  .overflow-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 19px;
    flex: 1;
  }

  .user {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 26px 1fr;
    column-gap: 8px;
    flex: 1;
    padding-right: 50px;
    height: 32px;

    &_clickable:hover {
      cursor: pointer;

      .user_name {
        text-decoration: underline;
      }
    }

    &.guest {
      .online-status_label {
        color: #565691;
      }
    }

    .user_icon {
      position: relative;
      grid-area: 1 / 1 / 3 / 2;
      display: flex;
      align-items: center;

      img,
      svg {
        border-radius: 2px;
        height: 26px;
        width: 26px;
      }

      .guest-icon {
        position: relative;
        background: #3273fa;
        padding: 5px;
      }
    }

    .user_name {
      grid-area: 1 / 2 / 2 / 3;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include side-nav-fade;

      @include side-nav-light-theme {
        color: #383864;
      }
    }

    .online-status_dot {
      position: absolute;
      right: -3px;
      bottom: -3px;
      z-index: 1;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid #191937;
      background: #0ade96;

      @include side-nav-light-theme {
        border: 2px solid #dfe7fc;
      }
    }

    .online-status_label {
      grid-area: 2 / 2 / 3 / 3;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      white-space: nowrap;
      @include side-nav-fade;

      @include side-nav-light-theme {
        color: #5f648a;
      }
    }
  }

  .footer_menu-container {
    position: absolute;
    right: 22px;
    display: flex;
    align-items: center;

    .footer_menu {
      position: absolute;
      bottom: calc(100% + 35px);
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      width: 150px;
      background: #191937;
      box-shadow: 0px 4px 12px 1px rgba(7, 7, 32, 0.6);
      border-radius: 2px;

      @include side-nav-light-theme {
        background: #dfe7fc;
        color: #5f648a;
        box-shadow: 0px 1px 4px rgba(14, 37, 84, 0.22);
      }
    }

    .footer_link {
      display: flex;
      align-items: center;
      padding: 0 18px;
      height: 33px;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: #cddcfe;

      @include side-nav-light-theme {
        color: #5f648a;
      }

      &:hover {
        background-color: #313160;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .three-dots {
      padding: 2px;
      width: 20px;
      height: 20px;
      border-radius: 1px;

      @include side-nav-light-theme {
        g,
        path {
          fill: #383864;
        }
      }

      &:hover {
        background: #5c5c8e;
        border-radius: 3px;
        cursor: pointer;

        @include side-nav-light-theme {
          background: #b2c5f1;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:97";