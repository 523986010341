.support-us-page {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  width: 100%;
  min-height: calc(100vh - #{$masthead-height});
  background-color: #e7eeff;

  .bg-container {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 460px;
    background-repeat: no-repeat;
    background-position: calc(50% + 450px) -130px;
    background-image: url("https://static.bigbrain.gg/assets/lol/backgrounds/support-us-bg.jpg");

    .overlay {
      width: 100%;
      height: 100%;
      background-image: radial-gradient(100% 100% at 50% 50%, rgba(7, 7, 32, 0) 0%, #070720 85%);
    }
  }

  h1 {
    margin-top: 80px;
    margin-bottom: 24px;
    font-family: "Barlow";
    font-size: 48px;
    font-weight: 600;
    color: #ffffff;
  }

  .premium-btn {
    @include flex-center;
    width: 260px;
    height: 48px;
    border-radius: 6px;
    background-color: #3273fa;
    box-shadow: 0px 6px 8px rgba(7, 7, 32, 0.3);
    font-size: 14px;
    font-weight: 700;
  }

  .dialogue-container {
    margin: 80px 24px;
    padding: 48px;
    // width: 100%;
    max-width: 800px;
    border-radius: 6px;
    border: 1px solid #383864;
    background-image: linear-gradient(225deg, #313160 -100%, #191937 100%);
    box-shadow: 1px 7px 6px rgba(7, 7, 32, 0.16);

    p {
      margin: 0;
      font-family: "Barlow";
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #cddcfe;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .lp-priority {
    display: flex;
    align-items: center;

    svg {
      margin-right: 24px;
      flex: 0 0 47px;
    }
  }

  .divider {
    margin: 48px 0;
    width: 100%;
    height: 1px;
    background-color: #383864;
  }

  .ugg-icon {
    width: 36px;
    path {
      fill: #a6b9e5;
    }
  }
}

;@import "sass-embedded-legacy-load-done:105";