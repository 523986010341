.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // align-items: safe center;
  width: 100%;

  &.landing-page_loading {
    justify-content: center;
    height: calc(100vh - #{$masthead-height});
  }

  .landing-header {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    // margin-top: 80px;

    .landing-text-container {
      display: flex;
      width: 1025px;
      padding-left: 75px;
      justify-content: space-between;

      .small-ugg-logo {
        height: 36px;
        margin-left: 4px;
        margin-bottom: 5px;
      }
      .UGG-logo {
        margin-bottom: 70px;
      }
    }

    .header {
      width: 100%;
      margin-top: 0;
      // text-shadow: 1px 1px 40px rgba(22, 23, 31, 0.8);
      margin-bottom: 30px; // font-family: "Prohibition";
      font-size: 55px;
      line-height: 60px;
      font-style: normal;
      font-weight: bold; // font-style: italic;
    }

    .sub-header {
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.content-title {
  margin-bottom: 24px;
  font-size: 14px;

  strong:nth-of-type(1) {
    color: var(--font-color-main);
  }
  strong:nth-of-type(2) {
    color: $grey-text;
  }
  span {
    color: $grey-text;
  }
}

.view-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 16px;
  padding: 9px 11px 11px;
  border: 1px solid $grey-text;
  border-radius: 8px;
  font-size: 11px;
  font-weight: bold;
  color: var(--font-color-main);
  text-decoration: none;

  &:hover {
    color: #ffffff;
    border: 1px solid var(--ugg-common-blue);
    background-color: var(--ugg-common-blue);
  }
}

;@import "sass-embedded-legacy-load-done:68";