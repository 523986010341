.champion-home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  max-width: 1014px;
  width: 100%;
  box-sizing: content-box;

  .champion-home-page_header {
    margin: 24px 0;
  }
}

.champion-leaderboard {
  max-width: 1014px;
  width: 100%;

  .leaderboard-ranking_filters-row {
    display: flex;
    align-items: center;
    margin: 24px 0;
    z-index: 100;

    .leaderboard_filters {
      display: flex;
      align-items: center;

      .filter-label {
        display: flex;
        align-items: center;
        margin-right: 24px;
        font-size: 14px;
        font-weight: 700;

        @include responsive("MOBILE_LARGE") {
          margin-right: 12px;
        }

        svg {
          width: 18px;
        }
        span {
          margin-left: 10px;
        }
      }

      .region-filter {
        margin-right: 12px;
      }
    }

    .filter-select-wrapper {
      z-index: 100;
    }
  }

  .leaderboard-rules {
    display: flex;
    margin: 24px auto;
    background-color: #11112a;
    max-width: 500px;
    padding: 26px;
    border-radius: 6px;

    @include responsive("TABLET") {
      padding: 12px;
    }

    img {
      margin-right: 26px;
    }

    span {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #cddcfe;
    }
  }

  .leaderboard_table_loading {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 1014px;

    @include responsive("TABLET") {
      width: 100%;
    }

    .spinthatshit-loader {
      align-items: flex-start;
      margin-top: 64px;
    }
  }

  .leaderboard_table_error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 12px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    img {
      margin-bottom: 15px;
      min-width: 50px;
      max-width: 200px;
    }

    a {
      text-decoration: underline;
    }
  }

  @import "../../components/Leaderboards/styles/leaderboard-top-five.scss";
  @import "../../components/Leaderboards/styles/leaderboard-table";
  @import "../../components/Leaderboards/styles/leaderboard-pagination";
}

;@import "sass-embedded-legacy-load-done:74";