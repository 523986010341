.basic-placeholder {
  position: relative;
  background: var(--border-color-grey);
  background-repeat: repeat;

  &.shimmer-placeholder {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      var(--border-color-grey) 0%,
      var(--border-color-grey) 40%,
      rgba(255, 255, 255, 0.1) 50%,
      var(--border-color-grey) 60%,
      var(--border-color-grey) 100%
    );
    background-size: 200% 50% !important;

    @keyframes placeHolderShimmer {
      0% {
        background-position: 100%;
      }
      100% {
        background-position: 0%;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:67";