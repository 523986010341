@mixin match-card-bg($color, $opacity, $isExpanded: false) {
  @if $isExpanded {
    //background: linear-gradient(180deg, rgba(25, 25, 55, 0) 0%, #191937 40%), linear-gradient(97deg, rgba($color, $startingOpacity) 0%, rgba($color, 0) 100%);
    //background: linear-gradient(0deg, rgba($color, $opacity), rgba($color, $opacity)), #191937;
    background: linear-gradient(135deg, rgba($color, 0.1) 0%, rgba($color, 0.1) 100%), #191937;
  } @else {
    //background: linear-gradient(135deg, rgba($color, $startingOpacity) 0%, rgba($color, 0) 100%), #191937;
    background: linear-gradient(0deg, rgba($color, $opacity), rgba($color, $opacity)), #191937;
  }
  &.is-hovered {
    background: linear-gradient(135deg, rgba($color, 0.1) 0%, rgba($color, 0.1) 100%), #191937;
  }
}

.match-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  background-color: #191937;

  &.match_gold {
    @include match-card-bg(#ff9b00, 0.4);
    &.is-expanded {
      @include match-card-bg(#ff9b00, 0.4, true);
    }
  }
  &.match_win {
    background-color: #1e2b5e;
    &.is-hovered,
    &.is-expanded {
      background-color: #172453;
    }
  }
  &.match_lose {
    background-color: #3b213b;
    &.is-hovered,
    &.is-expanded {
      background-color: #341934;
    }
  }
  &.match_remake {
    background-color: #191937;
    &.is-hovered,
    &.is-expanded {
      background-color: #151530;
    }
  }
}

@import "~@ugg/shared/stylesheets/summoner-profile/match-card/match-summary";
@import "~@ugg/shared/stylesheets/summoner-profile/match-card/expanded-match-card";
@import "~@ugg/shared/stylesheets/summoner-profile/match-card/post-game";
@import "~@ugg/shared/stylesheets/summoner-profile/match-card/multikill-indicator";
@import "~@ugg/shared/stylesheets/summoner-profile/match-card/expanded-performance";
@import "~@ugg/shared/stylesheets/summoner-profile/match-card/scoring-section";

;@import "sass-embedded-legacy-load-done:57";