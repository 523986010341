.lp-graph {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  background-color: #11112a;
  overflow: hidden;
  grid-row: 2 / 3;
  border-radius: 3px;

  @include responsive("DESKTOP_LARGE", false) {
    max-height: 230px;
    .chart-container {
      align-items: flex-end !important;
      height: 170px;
    }
  }
  @include responsive("DESKTOP_MEDIUM", false) {
    max-height: 230px;
    .chart-container {
      align-items: flex-end !important;
      height: 170px;
    }
  }

  // @include responsive("DESKTOP_SMALL", false) {
  //   grid-row: 1 / 2;
  //   max-height: none;
  // }
  // @include responsive("TABLET", false) {
  //   grid-row: 1 / 2;
  //   max-height: none;
  // }

  @include responsive("MOBILE_LARGE", false) {
    max-height: none;
  }

  @include responsive("MOBILE_MEDIUM") {
    grid-row: 2 / 3;
    max-height: 230px;

    .chart-container {
      height: 200px;
    }
  }

  .lp-graph_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 0;

    &.graph-spinner {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  .header-container {
    padding-bottom: 12px;
    background-color: #191937;

    @include responsive("TABLET") {
      padding: 17px 2px 12px 2px;
    }

    .lp-graph_header {
      margin-bottom: 0;
      justify-content: space-between;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;

      .flex-apart {
        display: flex;
        justify-content: space-between;
        color: #cddcfe;
      }

      .lp-graph_header__rank {
        display: flex;
        align-items: center;

        > img {
          margin-right: 4px;
          width: 22px;
        }
      }

      .match-lp {
        display: flex;
        align-items: center;
        margin-left: 4px;
        color: #cddcfe;
        font-weight: 500;
        font-size: 14px;

        .match-lp-value {
          margin-left: 4px;
        }

        .lp-gain-indicator {
          width: 6px;

          &.gain {
            transform: rotateX(180deg);
            path {
              fill: #3273fa;
            }
          }
          &.loss {
            path {
              fill: #ff4e50;
            }
          }
        }
      }
    }
  }

  .flex-apart_bottom {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    color: #5f5f7b;
    background-color: #191937;
    padding-top: 12px;
  }

  .current-season-rank {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 18px;
    border-radius: 6px;
    background-color: #11112a;
    font-size: 14px;
    font-weight: 500;
    color: #cddcfe;
    white-space: nowrap;

    .rank-text {
      font-weight: 700;
      color: #ffffff;
    }

    .tier-img {
      margin: 0 7px;
      width: 15px;
    }
  }

  .chart-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    overflow: hidden;

    &:hover {
      .lp-graph_data-label {
        display: none;
      }
    }

    .no-lp-message {
      @include flex-center;
      padding: 48px 24px;
      height: 100%;
      color: #cddcfe;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
  }
  .highcharts-area {
    visibility: visible !important;
  }
  .highcharts-tooltip-box {
    display: none;
  }

  .lp-graph_data-label {
    @include flex-center;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 5px;

    &.lp-graph_data-label_hidden {
      visibility: hidden;
    }

    &.lp-graph_data-label_promoted {
      .triangle-arrow {
        path {
          fill: #3273fa;
        }
      }
    }

    &.lp-graph_data-label_demoted {
      flex-direction: column-reverse;
      margin-top: 3px;

      .triangle-arrow {
        transform: rotate(180deg);

        path {
          fill: #ff4e50;
        }
      }
    }

    &.lp-graph_data-label_promoted-inverse {
      transform: translateY(-39px);
    }

    &.lp-graph_data-label_demoted-inverse {
      transform: translateY(39px);
    }

    .rank-text {
      font-family: "Helvetica Neue", "Roboto", "San-Serif";
      font-size: 12px;
      font-weight: 700;
    }

    .triangle-arrow {
      margin-top: 2px;
      width: 8px;

      path {
        fill: #ffffff;
      }
    }
  }

  .lp-graph_tooltip {
    font-family: "Helvetica Neue", "Roboto", "San-Serif";
    display: flex;
    font-size: 14px;

    .champion-img {
      position: relative;
      @include flex-center;
      margin-right: 12px;
      flex: 0 0 36px;
      width: 36px;
      height: 36px;
      border-radius: 6px;
      overflow: hidden;

      > img {
        flex: 0 0 100%;
        width: 100%;
        transform: scale(1.1);
      }
    }

    .triangle-svg {
      width: 7px;

      &.gain {
        transform: rotateX(180deg);
        path {
          fill: #3273fa;
        }
      }
      &.loss {
        path {
          fill: #ff4e50;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:55";