.ad-free-banner {
  position: relative;
  @include flex-center;
  flex-wrap: wrap;
  padding: 18px 65px 18px 22px;
  width: 100%;
  box-shadow: 0 1px 8px 2px rgba(7, 7, 32, 0.64);
  background-color: #3273fa;
  font-family: "Barlow";
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;

  > img {
    margin-right: 18px;
    width: 30px;
  }

  a {
    text-decoration: underline;
  }

  .x-icon {
    position: absolute;
    top: 32px;
    right: 36px;
    width: 20px;
    cursor: pointer;

    path {
      fill: #ffffff;
    }
  }
}

.ad-free-banner-worlds {
  position: relative;
  @include flex-center;
  //justify-content: left;
  flex-wrap: wrap;
  padding-top: 18px;
  padding-bottom: 18px;
  //padding-left: 502px;
  //padding-left: 300px ;
  //padding-right: 22px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(7, 7, 32, 0.57);

  //box-shadow: 0 1px 8px 2px rgba(7, 7, 32, 0.64);
  //background-color: #3273fa;
  .worlds-banner {
    margin-left: 24px;
    width: 50px;
    height: 50px;
  }

  .pbs-banner {
    width: 38px;
    height: 50px;
  }

  .text-box {
    text-align: left;
    //width: 566px;
    height: 47px;
    font-size: 18px;
    margin-left: 24px;

    @include responsive("DESKTOP_SMALL") {
      font-size: 14px;
    }

    .header {
      color: #000000;
      font-family: "Inter";
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
    }

    .sub-header {
      color: #5f5f7b;
      font-family: "Inter";

      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.2px;
      line-height: 24px;
      text-align: left;
    }
  }

  .masthead_pbs-btn {
    @include flex-center;
    width: 128px;
    height: 36px;
    margin-left: 24px;
    padding: 0px 6px;
    min-height: 30px;
    border-radius: 6px;
    background-color: #3273fa;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;

    .pbs-button {
      margin-right: 6px;
      padding-right: 6px;
      border-radius: 1px;
      border-right: 1px solid rgba(255, 255, 255, 0.25);
    }
  }
}

.app-banner {
  position: relative;
  @include flex-center;
  flex-wrap: wrap;
  // padding-top: 8px;
  height: 100px;
  width: 100%;
  background: linear-gradient(90deg, #ffffff 0%, #cddcfe 100%);
  box-shadow: 0px 4px 6px rgba(7, 7, 32, 0.56711);

  .app-preview {
    position: relative;
    top: 8px;
    display: flex;
    justify-content: space-between;
    width: 370px;
    height: 100%;

    .double-img {
      flex: 0 0 180px;
      width: 180px;
    }

    img {
      height: 92px;
    }
  }

  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: 18px;
    margin: 0px 96px 0px 36px;

    .header {
      color: #000000;
      font-family: "Inter";
      font-weight: bold;
      line-height: normal;
    }

    .sub-header {
      color: #000000;
      font-family: "Inter";
      letter-spacing: -0.2px;
    }
  }

  .button-container {
    @include flex-center;

    // height: 92px;
    // padding-bottom: 8px;
    .masthead_app-btn {
      @include flex-center;
      width: 142px;
      height: 40px;
      margin-left: 24px;
      padding: 0px 6px;
      min-height: 30px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 700;
      color: #ffffff;

      .win-icon {
        width: 16px;
        margin-right: 8px;
      }
    }
  }
}

// First Design of the GG Bet Ad Banner
// .ad-bet-banner {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100px;
//   width: 100%;
//   background: linear-gradient(288.38deg, #FF5C00 -142.15%, #14171C 63.98%);
//   box-shadow: 0px 4px 6px rgba(7, 7, 32, 0.56711);
//   border-bottom: #FF5C00;
//   border-bottom-width: 1px;
//   border-bottom-style: solid;

//   .bet-svg{
//     width:231px;
//     height:44px;
//     min-width: 231px;
//     max-width: auto;
//     min-height: 44px;
//     max-height: auto;
//   }

// .ad-indication{
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   width: 20px;
//   height: 17px;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #CDDCFE;
//   background: rgba(20, 23, 28, 0.6);;
// }

//   .ad-match-box-background{
//     display: flex;
//     margin-left: 31px;
//     padding: 5px;
//   }

//   .ad-match-box-background:hover{
//     background:rgba(255, 92, 0, 0.2);
//   }

//   .match-box {
//     text-align: left;
//     font-size: 18px;
//     width: 220px;

//     .match-footer {
//       justify-content: space-between;
//       display: flex;
//       font-family: 'Barlow';
//       font-style: italic;
//       font-weight: 600;
//       font-size: 14px;
//       line-height: 17px;
//       text-transform: uppercase;
//       color: #FF5C00;
//       margin-top: 7px;

//       .combo-text {
//         display: flex;

// .time-tag {
//   padding-right: 12px;

//   font-family: 'Barlow';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 17px;
//   text-align: right;
//   text-transform: uppercase;
//   color: #CDDCFE;

//   .live-game-text{
//     display:flex;
//     font-family: 'Barlow';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 17px;

//     text-align: right;
//     text-transform: uppercase;
//     align-items: center;

//     color: #FFFFFF;
//     .live-red-dot{
//       width: 14px;
//       height: 14px;
//       margin-top:2px;

//     }

//           }
//         }

//         .bet-now-button{
//           font-family: 'Inter';
//           font-style: normal;
//           font-weight: 700;
//           font-size: 12px;
//           line-height: 15px;
//           color: #FFFFFF;
//           background: #FF5C00;
//           border-radius: 2px;
//           padding: 1px 3px;
//         }
//       }
//     }

// .match-body {
//   height: 64px;
//   background: linear-gradient(180deg, rgba(20, 23, 28, 0.7) 0%, rgba(20, 23, 28, 0) 100%);
//   border-top: #FF5C00;
//   border-top-width: 1px;
//   border-top-style: solid;

//   border-bottom: rgba(255, 92, 0, 0.2);;
//   border-bottom-width: 1px;
//   border-bottom-style: solid;

//   font-family: 'Barlow';
//   font-style: italic;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 17px;
//   display: flex;
//   flex-direction: column;
//   .match-body-team {
//     display: flex;
//     justify-content: space-between;
//     padding-left: 12px;
//     padding-right: 12px;
//     padding-top: 3px;
//     height: 27px;
//     align-items: center;
//     .match-body-logo-name {
//       display: flex;
//       align-items: center;
//       .match-body-name {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: 600;
//         font-size: 14px;
//         line-height: 17px;
//         color: #FFFFFF;
//         padding-left: 6px;
//         width:120px;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         overflow:hidden;
//       }
//       .match-body-logo {
//         max-height: 24px;
//         max-width: 24px;
//       }
//     }
//     .match-body-data-odds{
//       background: #14171C;
//       border-radius: 2px;
//       font-family: 'Inter';
//       font-style: normal;
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 15px;
//       text-align: center;
//       color: #CDDCFE;
//       width:42px;
//       display:flex;
//       justify-content: center;
//       padding: 1px 8px 1px 9px;
//     }
//   }
//     }
//     .match-body-highlight{
//       background: linear-gradient(180deg, rgba(255, 92, 0, 0.2) 0%, rgba(255, 92, 0, 0) 100%);
//     }
//   }
// }

//Worlds version of the Ad Banner
.gg-bet-banner-worlds-promo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: #1c1c1c;
  box-shadow: 0px 4px 6px rgba(7, 7, 32, 0.56711);
  border-bottom: #ff5c00;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  .gg-bet-logo {
    width: 199px;
    height: 39px;
    min-width: 199px;
    max-width: auto;
    min-height: 39px;
    max-height: auto;
    margin-right: 36px;
  }

  .gg-bet-event {
    width: 809px;
    height: 32px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    align-items: center;

    color: #ffffff;
    background: #151515;
    border-radius: 1px;
    margin-bottom: 3px;

    .league-symbol-svg {
      height: 20px;
      width: 20px;
      justify-content: center;
      margin: 6px;
      float: left;
    }
    .gg-bet-event-name {
      float: left;
      text-align: center;
      justify-content: center;
      margin: 10px;
    }

    .ggbet-logo-small-svg {
      height: 12px;
      width: 62px;
      justify-content: center;
      margin: 10px;
      left: 179px;
      float: right;
    }
  }

  .gg-bet-event-matches {
    display: flex;

    .gg-bet-event-match-highlight {
      background: #1e1e1e;
    }

    .gg-bet-event-match:hover {
      background: #2e2e2e;
    }

    .time-tag-container {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .time-tag-time {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;

        .live-indication-text {
          width: 33px;
          height: 17px;
          min-width: 33px;
          min-height: 17px;
          overflow: visible;
        }
      }

      .time-tag-date {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #a3afcc;
      }
    }

    .gg-bet-event-match {
      display: flex;
      background: #151515;
      border-radius: 1px;
      width: 200px;
      height: 49px;
      margin-right: 3px;
    }

    .team-container {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;

      //Formating for the boxes of each.
      .match-body-team {
        display: flex;
        justify-content: space-between;
        height: 27px;
        align-items: center;

        .match-body-logo-name {
          display: flex;
          align-items: center;

          .match-body-name {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #ffffff;
            padding-left: 6px;
            width: 95px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .match-body-logo {
            max-height: 18px;
            max-width: 18px;
          }
        }

        .match-body-data-odds {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #cddcfe;
          width: 35px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .gg-bet-promo-message {
    align-items: center;
    width: 254px;
    text-align: center;
    margin-left: 36px;

    .gg-bet-promo-message-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      color: #cddcfe;
      height: 15px;
    }

    .gg-bet-promo-message-code {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      background: #1e1e1e;
      border: 0.5px dashed #a3afcc;
      border-radius: 3px;
      height: 26px;
      margin-top: 7px;
      display: flex;
      align-items: center;
    }

    .gg-bet-promo-message-button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      background: #ff5c00;
      border-radius: 2px;
      height: 26px;
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    .gg-bet-promo-message-button:hover {
      background: #b03200;
    }
  }

  .match-body {
    height: 64px;
    background: linear-gradient(180deg, rgba(20, 23, 28, 0.7) 0%, rgba(20, 23, 28, 0) 100%);
    border-top: #ff5c00;
    border-top-width: 1px;
    border-top-style: solid;

    border-bottom: rgba(255, 92, 0, 0.2);
    border-bottom-width: 1px;
    border-bottom-style: solid;

    font-family: "Barlow";
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;

    .match-body-team {
      display: flex;
      justify-content: space-between;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 3px;
      height: 27px;
      align-items: center;

      .match-body-logo-name {
        display: flex;
        align-items: center;

        .match-body-name {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          padding-left: 6px;
          width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .match-body-logo {
          max-height: 24px;
          max-width: 24px;
        }
      }

      .match-body-data-odds {
        background: #14171c;
        border-radius: 2px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #cddcfe;
        width: 42px;
        display: flex;
        justify-content: center;

        padding: 1px 8px 1px 9px;
      }
    }
  }
  .match-body-highlight {
    background: linear-gradient(180deg, rgba(255, 92, 0, 0.2) 0%, rgba(255, 92, 0, 0) 100%);
  }

  .ad-indication {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 17px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cddcfe;
    background: rgba(20, 23, 28, 0.6);
  }
}

;@import "sass-embedded-legacy-load-done:49";