.login-page,
.signup-page {
  #page-content {
    padding-bottom: 0px;
    min-height: 100vh;
  }
}

.login-outer,
.signup-outer,
.recover-account-form-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 128px;
  gap: 8px;

  .back-to-home {
    display: flex;
    font-size: 14px;
    max-width: 500px;
    width: 90%;

    a {
      color: #cddcfe;
    }
  }
}

.login,
.signup,
.recover-account-form {
  display: flex;
  color: #ffffff;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  background-color: #11112a;
  border-radius: 6px;
  padding: 48px;
  max-width: 500px;

  @media screen and (max-width: 510px) {
    padding: 15px;
  }

  .premium-flow-header {
    margin-bottom: 12px;
    text-align: center;

    span {
      font-family: "Barlow";
      font-size: 28px;
      font-weight: 600;

      &.boost-text {
        margin-right: 5px;
        font-weight: 700;
        font-style: italic;
        color: $volxd-tier;
      }
    }
  }

  .logo {
    cursor: pointer;
    @media screen and (max-width: 510px) {
      width: 130px;
    }
  }

  .login_form {
    width: 100%;
  }

  .login-error,
  .signup-error {
    border-radius: 3px;
    width: 100%;
    height: 42px;
    margin-bottom: 24px;
    font-size: 14px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }

  .divider {
    width: 400px;
    height: 1px;
    background-color: #bbbedb;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .sign {
    font-size: 30px;
    font-weight: 500;
    font-family: "Barlow";
    color: white;
    margin-bottom: 12px;

    @media screen and (max-width: 510px) {
      font-size: 24px;
    }
  }

  .already-have-account {
    font-size: 16px;
    font-weight: 500;
    color: #cddcfe;
    margin-bottom: 45px;

    @media screen and (max-width: 510px) {
      font-size: 14px;
    }

    span {
      color: #3273fa;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: lighten(#3273fa, 8%);
      }
    }
  }

  .custom-input-container .input-label {
    color: #cddcfe;
    font-family: "Helvetica Neue", "Roboto", Arial;
    font-size: 14px;
    margin-bottom: 10px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #25254b inset !important;
    -webkit-text-fill-color: white !important;
    caret-color: white;
    background-clip: content-box !important;
  }

  .custom-input-container input {
    font-size: 16px;
    color: #ffffff;
    height: 42px;
    box-shadow: 0 2px 4px rgba(14, 37, 84, 0.22);
    border-radius: 3px;
    border: 1px solid #31334a;
    background-color: #25254b;

    @media screen and (max-width: 510px) {
      width: 95%;
    }

    &:focus {
      outline: none;
      border-color: #919496 !important;
    }

    &::placeholder {
      color: #ffffff;
      font-weight: 400;
    }
  }

  .password-checker {
    margin-top: 24px;
    color: #ffffff !important;
  }

  .summoner-finder {
    margin-bottom: 24px;
  }

  .summoner-name-error {
    margin-bottom: 3px;
    color: $ugg-red;
    font-size: 14px;
  }

  .text-link {
    margin-top: -1px;
  }

  a.forgot,
  a.forgot:hover,
  a.forgot:visited,
  a.forgot:active,
  a.forgot:focus {
    color: #ffffff;
    text-decoration: underline;
  }

  .select_modal__toggle.region-selector-button {
    .arrow-icon svg {
      g,
      path {
        fill: $ugg-sub-grey;
      }
    }
  }

  .modal-filter__open .region-selector-button {
    background-color: #31334a !important;
  }

  .select_modal__toggle.region-selector-button:hover {
    // background-color: #CDDCFE !important;
    // background-color: #31334a !important;
  }

  .select_modal {
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .terms {
    font-size: 12px;
    color: #cddcfe;
    margin-bottom: 24px;
  }

  .login-submit,
  .signup-submit {
    font-size: 16px;
    height: 42px;
    border-radius: 4px;
    background-color: $ugg-blue;
    border: none;
  }

  .login-submit.btn-gray {
    width: 400px;
    height: 42px;
    border-radius: 4px;
    background-color: #222238;
    // background-color: #1c1c32;
  }

  .summoner-finder .custom-input-container input {
    padding: 0 9px 0 78px;
  }

  .checklist-item_label {
    color: #ffffff;
  }
}

.signup .logo-container {
  margin-bottom: 48px;

  @media screen and (max-width: 510px) {
    margin-bottom: 24px;
  }
}

.login {
  max-width: 500px;
  width: 90%;
  border-radius: 6px;
  padding: 48px;

  @media screen and (max-width: 510px) {
    padding: 24px;
  }

  background-color: #11112a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .white-bold {
    color: white;
    font-size: 30px;
    font-weight: 500;
    font-family: "Barlow";

    @media screen and (max-width: 510px) {
      font-size: 24px;
    }
  }

  .basic-text {
    color: #cddcfe;
    font-family: "Helvetica Neue", "Roboto", Arial;
    font-size: 16px;
    margin-bottom: 48px;

    @media screen and (max-width: 510px) {
      margin-bottom: 24px;
    }

    span {
      color: #3273fa;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: lighten(#3273fa, 10%);
      }
    }
  }

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .forgot-your-password {
    text-align: center;
    font-weight: bold;
    color: var(--ugg-common-blue);
  }

  .login-submit {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .forgot {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .create {
    font-size: 16px;
    font-weight: 350;
  }
}

;@import "sass-embedded-legacy-load-done:48";