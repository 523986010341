@mixin game-bg($game, $default-bg, $hover-bg) {
  &_#{$game} {
    &.game_active {
      background-color: $default-bg;

      &:hover {
        background-color: $hover-bg;
      }
    }
  }
}

.side-nav_game-navigator {
  margin: 0 12px;

  .game-selector {
    display: flex;
    gap: 12px;
    transition: gap $side-nav-transition-duration;

    > * {
      display: contents;
    }

    .side-nav_collapsed & {
      gap: 0;
    }

    @include side-nav-light-theme {
      border-bottom: 1px solid #dbdfef;
    }

    .game {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 36px;
      border-radius: 3px;
      opacity: 1;
      overflow: hidden;
      transition: flex $side-nav-transition-duration, opacity $side-nav-transition-duration;

      &:not(.game_active) {
        .side-nav_collapsed & {
          flex: 0;
          opacity: 0;
        }
      }

      &.game_active,
      &:hover {
        background: #191937;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        @include side-nav-light-theme {
          background: #dfe7fc;
          box-shadow: 0px 1px 4px rgba(14, 37, 84, 0.22);
        }
      }

      svg {
        flex: 0 0 20px;
      }

      @include game-bg("lol", #2c2326, #3f3337);
      @include game-bg("valorant", #39142b, #4b1e3a);
      @include game-bg("wow", #381f16, #492c22);
    }
  }

  .game-navigator_link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 40px;
    border-radius: 3px;
    background-color: #2c2326;
    overflow: hidden;

    @include side-nav-light-theme {
      background: linear-gradient(268.56deg, rgba(227, 178, 90, 0.3) 0%, rgba(191, 146, 62, 0.3) 100%), #f4f7ff;
    }
  }

  .game-navigator_link__icon {
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .game-navigator_link__label {
    flex: 1;
    padding-left: 32px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    white-space: nowrap;
    @include side-nav-fade;

    @include side-nav-light-theme {
      color: #383864;
    }
  }

  .game-navigator_link__dropdown-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: rgba(7, 7, 32, 0.2);

    @include side-nav-light-theme {
      background-color: rgba(191, 146, 62, 0.6);
    }

    svg {
      flex: 0 0 8px;
      transition: rotate 0.2s;

      g,
      path {
        fill: #ffffff;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:98";