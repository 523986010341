$breakpoint1: 1100px;
$breakpoint2: 850px;
$breakpoint3: 700px;
$breakpoint4: 500px;

.footer {
  z-index: unset !important;
}

#footer {
  font-family: "Barlow";
  padding-top: 120px;
  padding-bottom: 100px;
  width: 100%;
  background-color: #0c0c21;

  @media screen and (max-width: $breakpoint2) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto -24px;
    // width: 100%;
    max-width: 1016px;
    box-sizing: content-box;

    @media screen and (max-width: $breakpoint1) {
      max-width: 800px;
    }
    @media screen and (max-width: $breakpoint2) {
      max-width: 650px;
    }
    @media screen and (max-width: $breakpoint3) {
      flex-direction: column;
    }

    .footer_section {
      display: flex;
      flex-direction: column;
      max-width: 445px;
      font-size: 18px;
      font-weight: 500;

      @media screen and (max-width: $breakpoint1) {
        max-width: 220px;
        font-size: 16px;
      }
      @media screen and (max-width: $breakpoint2) {
        font-size: 14px;
      }
      @media screen and (max-width: $breakpoint3) {
        max-width: none;
      }

      .footer_header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 32px;
        height: 46px;
        font-family: "Proxima Nova";
        font-size: 24px;
        font-weight: 700;

        @media screen and (max-width: $breakpoint3) {
          margin-bottom: 24px;
        }
      }
    }

    .disclaimers {
      margin-bottom: 24px;
      .footer_ugg-logo {
        margin-bottom: 30px;
        width: 143px;
      }
      .copyright {
        margin-bottom: 30px;
        color: #ffffff;
        // font-size: 18px;
        font-weight: 600;
      }
      .disclaimer {
        line-height: 27px;
        color: #cddcfe;

        @media screen and (max-width: $breakpoint1) {
          line-height: 24px;
        }
      }
    }
    .network {
      margin-bottom: 24px;
      .links {
        display: flex;
        flex-direction: column;
        margin-bottom: -12px;

        @media screen and (max-width: $breakpoint3) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        a {
          display: flex;
          align-items: center;
          margin-right: 24px;
          margin-bottom: 12px;
          color: #cddcfe;
          // font-size: 18px;
          font-weight: 500;
          white-space: nowrap;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .connect-with-us {
      margin-bottom: 24px;

      .social-links {
        display: flex;
        flex-direction: column;
        margin-bottom: -12px;

        @media screen and (max-width: $breakpoint3) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        .social-link {
          display: flex;
          align-items: center;
          margin-right: 24px;
          margin-bottom: 12px;
          color: #cddcfe;
          // font-size: 18px;
          font-weight: 500;
          &:hover {
            color: #ffffff;
          }
          svg {
            margin-right: 18px;
            width: 22px;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:43";