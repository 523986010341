// scrollbar
// ::-webkit-scrollbar {
//   width: 4px;
//   height: 4px;
//   background-color: transparent;
// }

// ::-webkit-scrollbar-thumb {
//   border-width: 0;
//   background-color: #4d4d4d;
//   background-clip: padding-box;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: #808080;
// }

// ::-webkit-scrollbar-corner {
//   background-color: transparent;
// }

// ::-webkit-scrollbar-track-piece {
//   background-color: transparent;
// }

@mixin clean-scrollbar($scrollbar-width, $track-bg, $track-border-radius, $thumb-bg, $thumb-border-radius, $thumb-border-width) {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-bg;
    border-radius: $thumb-border-radius;
    border: $thumb-border-width solid $track-bg;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-bg;
    border-radius: $track-border-radius;
  }
}

;@import "sass-embedded-legacy-load-done:92";